<template>
  <LayoutContainer class="bg-blur bg-dark sticky top-0 z-10 py-4">
    <div class="flex items-center justify-between">
      <NuxtLink to="/">
        <img src="/img/logo-contigo.png" alt="Contigo" class="h-10 lg:h-14" />
      </NuxtLink>

      <AuthState>
        <template #default="{ loggedIn, clear }">
          <template v-if="loggedIn">
            <PvSplitButton
              severity="contrast"
              :model="[
                {
                  label: 'Cambiar contraseña',
                  icon: 'icon-[ph--key-fill]',
                  command: () => navigateTo('/user/change-password'),
                },
                {
                  label: 'Cerrar sesión',
                  icon: 'icon-[ph--sign-out-bold]',
                  command: () => {
                    clear().then(() => {
                      navigateTo('/')
                    })
                  },
                },
              ]"
              @click="navigateTo('/user/profile')"
            >
              <div class="flex items-center gap-2">
                <span class="icon-[ph--user-circle-fill] text-2xl" />
                <span class="text-md hidden font-bold uppercase lg:inline">Mi cuenta</span>
              </div>
            </PvSplitButton>
          </template>
          <template v-else>
            <PvButton severity="contrast" @click="navigateTo('/user/login')">
              <div class="flex items-center gap-2">
                <span class="icon-[ph--user-circle-fill] text-2xl" />
                <span class="text-md hidden font-bold uppercase lg:inline">Acceder</span>
              </div>
            </PvButton>
          </template>
        </template>
        <template #placeholder>
          <PvButton severity="contrast">
            <div class="flex items-center gap-2">
              <span class="icon-[ph--user-circle-fill] text-2xl" />
              <span class="text-md hidden font-bold uppercase lg:inline">Cargando</span>
            </div>
          </PvButton>
        </template>
      </AuthState>
    </div>
  </LayoutContainer>
</template>
