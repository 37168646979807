<script lang="ts" setup>
defineProps<{ minimal?: boolean }>()

const { contact } = useContent()

const navInfoOptions = [
  {
    label: 'Contáctanos',
    icon: 'ph--chat-centered-dots-fill',
    to: '/about/contact',
  },
  {
    label: 'Políticas de privacidad',
    icon: 'ph--pencil-simple-line-fill',
    to: '/about/privacy-terms',
  },
  {
    label: 'Términos y condiciones',
    icon: 'ph--book-open-fill',
    to: '/about/terms-conditions',
  },
  {
    label: 'Derechos y deberes de los usuarios',
    icon: 'ph--user-rectangle-fill',
    to: '/about/user-rights',
  },
  {
    label: 'Política de Seguridad de Pagos',
    icon: 'ph--shield-check-fill',
    to: '/about/payments-conditions',
  },
  {
    label: 'Políticas de Devoluciones, Reembolsos y Cancelaciones',
    icon: 'ph--currency-circle-dollar-fill',
    to: '/about/refunds-conditions',
  },
]

const paymentsBadges = [
  '/img/payments-badges/american-express.jpg',
  '/img/payments-badges/mastercard.jpg',
  '/img/payments-badges/visa.jpg',
]

const brands = ['visa', 'mastercard', 'american-express'] as const

const navInfoContact = [
  {
    label: contact.value.address.contact,
    icon: 'mdi--map-marker',
    to: contact.value.address.href,
  },
  ...contact.value.others
    .filter((item) => item.type === 'c-b')
    .map((item) => ({
      label: item.contact,
      icon: item.icon,
      to: item.href,
    })),
]
</script>

<template>
  <LayoutContainer>
    <div class="grid gap-4 py-4">
      <div v-if="!minimal" class="grid gap-4 text-sm md:grid-cols-3">
        <MenuComponent :items="navInfoOptions" class="order-2 md:order-1" />
        <div class="order-1 grid gap-6 text-center md:order-2">
          <div>
            <div class="py-2">DESCARGA NUESTRA APP</div>
            <div class="flex justify-center gap-2">
              <img src="/img/app-stores/google-play-badge.png" class="max-w-[40%]" />
              <img src="/img/app-stores/app-store-badge.png" class="max-w-[40%]" />
            </div>
          </div>
          <div class="grid gap-2">
            <span>SÍGUENOS EN LAS REDES</span>
            <div class="flex justify-center gap-2">
              <span class="icon-[mdi--facebook-box] text-4xl"></span>
              <span class="icon-[mdi--instagram] text-4xl"></span>
              <span class="icon-[mdi--twitter-box] text-4xl"></span>
            </div>
          </div>
          Nuestros procesadores de pago aceptan:
          <div class="flex justify-center gap-2">
            <template v-for="brand in brands" :key="brand">
              <SvgCcBrand :size="32" :brand="brand"></SvgCcBrand>
            </template>
          </div>
          <div>
            <div class="flex justify-center gap-2">
              <template v-for="badge in paymentsBadges" :key="badge">
                <img :src="badge" class="w-[80px] rounded" />
              </template>
            </div>
          </div>
        </div>
        <MenuComponent :items="navInfoContact" class="order-3 hidden md:inline" />
      </div>
      <div class="flex flex-col justify-center gap-4 text-center">
        <div class="flex justify-center">
          <img
            src="https://certificaciones.uaf.gob.do/certificados/UAF00295LKNR.png"
            class="h-28"
          />
        </div>
        <div>
          <div class="font-bold">
            CONTIGO SOLUCIONES S.A.S. - &copy; {{ new Date().getFullYear() }}
          </div>
          <div class="text-sm">
            Todos los productos presentados han sido previamente acordados con las aseguradoras
            aliadas y son administrados por CONTIGO SOLUCIONES S.A.S., corredor moral de seguros
            licencia CS-1374.
          </div>
        </div>
      </div>
    </div>
  </LayoutContainer>
</template>
